import React, { Component } from 'react'
import styled, { css } from 'react-emotion'

const Container = styled.section`
  background-color: ${props => props.theme.colors.gray_2};
  width: 100%;
`

const Content = styled.div`
  padding: 1.5rem;
  display: grid;
  justify-content: center;
`

const CallToAction = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.primary_active};
    outline: none;
  }
`

const Headline = styled.h2`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-top: 20px;
`

const SubHeadline = styled.p`
  text-align: center;
`

const Copy = styled.p`
  max-width: 58ch;
`

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

class GoPlay extends Component {
  constructor(props) {
    super(props)
  }
  handleCTAClick = () => {
    window.location = 'https://mastery.games/serviceworkies'
  }
  render() {
    return (
      <Container>
        <Content>
          <div>
            <Headline>You Need a Progressive Web App</Headline>
            <Copy>
              The web doesn't have to be a second-class platform. Your site
              doesn't have to go down when the internet does. New tech built
              into all modern browsers enables a savvy dev like yourself to
              build reliable experiences for your users.
            </Copy>

            <Copy>
              All it takes is to learn the magic of the{' '}
              <strong>Service Worker</strong> — a powerful but tricky little
              script that when done right enables all sorts of amazing things
              like true offline support, insanely-good performance, background
              sync, caching. You name it. Service Workers are one of the most
              important things a web dev can learn.
            </Copy>

            <Headline>Introducing Service Workies</Headline>
            <Copy>
              Meet Kolohe. He thinks he knows an awful lot about Service Workers
              because well, he <em>is</em> one.
            </Copy>

            <Copy>
              <video
                playsInline
                autoPlay
                loop
                className={css`
                  max-width: 100%;
                `}
              >
                <source src="/sw-wide.mp4" type="video/mp4" />
              </video>
            </Copy>

            <Copy>
              But Kolohe is soon to find out — as you might well know if you've
              ever attempted using Service Workers — there be dragons. In his
              case the dragons are non-figurative and are trying to eat him.
            </Copy>

            <Copy>
              In this new adventure you'll learn with Kolohe how to avoid the
              PWA pitfalls. You'll level up your skills and thrive. Perhaps even
              slay the savage beasts that have plagued the poor village workies
              for centuries.
            </Copy>
          </div>
        </Content>
      </Container>
    )
  }
}

export default GoPlay
