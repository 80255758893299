import React from 'react'
import styled, { css } from 'react-emotion'

const Container = styled.section`
  background-color: ${props => props.theme.colors.gray_1};
  width: 100%;
`

const Headline = styled.h2`
  max-width: 500px;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 30px;
`

const ListItem = styled.li`
  line-height: 28px;
  letter-spacing: 0;
  list-style-type: square;
`
const Image = styled.img`
  margin-left: 0px;
`

const Left = styled.div`
  justify-self: end;
  align-self: center;
  padding: 1.5rem;
`

const Right = styled.div`
  justify-self: start;
`

const Bullets = () => (
  <Container>
    <Content>
      <Left>
        <Headline>Learn Service Workers</Headline>
        <ul>
          <ListItem>Easily make your web app work 100% offline</ListItem>
          <ListItem>Achieve insane performance boosts</ListItem>
          <ListItem>Deliver native-feeling experiences</ListItem>
        </ul>
      </Left>
      <Right>
        <img
          className={css`
            width: 400px;
            margin-bottom: 0;
            /* position: relative;
            bottom: 30px; */
            transform: scale(1.1);
            transform-origin: center;
            align-self: start;
          `}
          // src="/hut-destroyed.png"
          src="/sturdy-hut.png"
        />
      </Right>
    </Content>
  </Container>
)

export default Bullets
