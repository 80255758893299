import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'
const Bracket = styled.span`
  display: inline-block;
  transform: scale(1.8, 1.8);
  position: relative;
  bottom: 0.1rem;
  margin: 0.2rem;
`
const Wrapper = styled.div`
  font-family: 'Source Code Pro';
  font-weight: 600;
  letter-spacing: 0.4rem;
  white-space: nowrap;
  ${props => {
    if (props.size === 'small') {
      return `
        font-size: 0.90rem;
        @media (min-width: 600px) {
          font-size: 1rem;
        }
      `
    } else if (props.size === 'tiny') {
      return `
        font-size: .75rem;
      `
    } else {
      return `
        font-size: 1.5rem;
      `
    }
  }};
`
export default props => (
  <Wrapper size={props.size}>
    <a
      href="https://gedd.ski"
      className={css`
        text-decoration: none;
        color: #ffffff;
      `}
    >
      <Bracket>{`{`}</Bracket>
      <span
        className={css`
          margin-right: 0.18em;
        `}
      >
        geddski
      </span>
      <Bracket>{`}`}</Bracket>
    </a>
  </Wrapper>
)
