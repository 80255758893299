import React from 'react'
import styled from 'react-emotion'

const Container = styled.section`
  background-color: ${props => props.theme.colors.gray_2};
  /* display: grid;
  justify-content: center; */
`

const Content = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: 1.5rem;
  justify-content: center;
  justify-items: center;
`

const Screenshot = styled.div`
  margin: 0;
  min-width: 320px;
  max-width: 500px;
  /* max-width: 100%; */
`

const Screenshots = () => (
  <Container>
    <Content>
      <Screenshot>
        <img src="/kolohe.jpg" alt="Kolohe Service Worker" />
      </Screenshot>
      <Screenshot>
        <img src="/lani.jpg" alt="Lani Service Worker" />
      </Screenshot>
      <Screenshot>
        <img src="/crow.jpg" alt="Crow Offline Beast" />
      </Screenshot>
    </Content>
  </Container>
)

export default Screenshots
