import React, { Component } from 'react'
import styled from 'react-emotion'
import { CTA_ID } from './hero'

const Container = styled.section`
  background-color: ${props => props.theme.colors.gray_2};
  width: 100%;
`

const Content = styled.div`
  max-width: calc(1080px + 60px);
  margin: auto;
  padding: 1.5rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CallToAction = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.primary_active};
    outline: none;
  }
`

const Headline = styled.h2`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
`

const SubHeadline = styled.p`
  text-align: center;
`

class GoPlay extends Component {
  constructor(props) {
    super(props)
  }
  handleCTAClick = () => {
    window.location = 'https://mastery.games/serviceworkies'
  }
  render() {
    return (
      <Container>
        <Content>
          <Headline>Time to Level Up</Headline>
          <SubHeadline>
            You'll be building awesome Progressive Web Apps (PWAs) in no time.
          </SubHeadline>
          <CallToAction onClick={this.handleCTAClick}>Play Now</CallToAction>
        </Content>
      </Container>
    )
  }
}

export default GoPlay
