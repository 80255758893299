import React, { Component } from 'react'
import styled from 'react-emotion'
import Logo from './geddskiLogo.js'

const BannerContainer = styled.section`
  background-color: ${props => props.theme.colors.gray_1};
  padding: 60px 30px;
  text-align: center;
  /* display: grid; */

  @media (min-width: 600px) {
    padding: 140px;
  }

  a {
    color: #429abe;
  }
`

const SWLogo = styled.img`
  margin: none;
  display: block;
  margin: 0 auto;
  width: 80%;
`

const ChromeLogo = styled.img`
  width: 40px;
`

const TagLine = styled.div`
  max-width: 560px;
  margin: 50px auto 25px;

  @media (min-width: 600px) {
    margin: 100px auto 50px;
  }
`

const CallToAction = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.primary_active};
    outline: none;
  }
`

const Strike = styled.span`
  text-decoration: line-through;
`

export const CTA_ID = 'cta_hook'

class Header extends Component {
  handleCTAClick = () => {
    window.location = 'https://mastery.games/serviceworkies'
  }
  render() {
    return (
      <BannerContainer>
        <SWLogo src="/logo-transparent.png" alt="Service Workies Logo" />
        <TagLine>
          Learn Service Workers inside and out with the new game of Service
          Worker mastery
          <br />A collaborative project by{' '}
          <a href="https://gedd.ski">geddski</a> & &nbsp;
          <a href="https://web.dev">Google Developers</a>
        </TagLine>

        {/* <Logo size="small" /> */}
        {/* <ChromeLogo alt="Chrome Developers" src="/chrome-developers.png" /> */}

        <CallToAction onClick={this.handleCTAClick}>
          Play Now &nbsp;
          <Strike>$179</Strike> FREE
        </CallToAction>
      </BannerContainer>
    )
  }
}

export default Header
