import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Screenshots from '../components/screenshots'
import Bullets from '../components/bullets'
import GoPlay from '../components/goplay'
import Story from '../components/story'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <Hero />
    <Screenshots />
    <Story />
    <Bullets />
    <GoPlay />
    <Footer />
  </Layout>
)

export default IndexPage
