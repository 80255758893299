import React from 'react'
import styled from 'react-emotion'
import Logo from './geddskiLogo.js'
import FacebookLogo from '../assets/facebook.svg'
import TwitterLogo from '../assets/twitter.svg'
import { Link } from 'gatsby'

const Container = styled.section`
  background-color: ${props => props.theme.colors.gray_3};
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 600px) {
    padding: 60px;
  }
`

const Copyright = styled.div`
  text-align: right;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
`

const Social = styled.div`
  white-space: nowrap;
`

// const FL = styled(FacebookLogo)`
//   margin-right: 14px;
// `

const Footer = () => (
  <Container>
    <Logo size="small" />
    <Social>
      <a href="https://twitter.com/geddski">
        <TwitterLogo />
      </a>
    </Social>
    <Copyright>copyright © 2016-2116 Dave Geddes</Copyright>
  </Container>
)

export default Footer
